import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import Select from "react-select";
import axios from "axios";
import { uniqBy, mean, get, isNull } from "lodash";
import ReactTooltip from "react-tooltip";
import "react-circular-progressbar/dist/styles.css";
import { AiFillEye } from "react-icons/ai";
import { BsChevronExpand } from "react-icons/bs";
import {
  MdCheckCircle,
  MdFileDownload,
  MdError,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { IoRemoveCircle } from "react-icons/io5";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { BiHelpCircle } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { downloadCSV } from "download-csv";

import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dropzone from "react-dropzone";

import ExtractorTable from "./components/ExtractorTable";
import Header from "../Header";
import RefreshIcon from "@material-ui/icons/Refresh";
import ExtractedScoreBar from "./components/ExtractedScoreBar";

import Tooltip from "@material-ui/core/Tooltip";

import {
  urlApiUpload,
  urlApiGetWords,
  urlApiDownloadAll,
  urlApiListAllFiles,
  urlApiDeleteFile,
  composeApiListFilesFromAuthor,
  composeApiListFilesFromCurrentSession,
  composeDownloadFiles,
} from "./endpoints";

const csvHeader = [
  { label: "filename", key: "filename" },
  { label: "item", key: "item" },
  { label: "score", key: "score" },
  { label: "candidate", key: "candidate" },
  { label: "status", key: "status" },
  { label: "selected", key: "selected" },
];

const BorderLinearProgress = withStyles((...args) => ({
  root: {
    borderRadius: 5,
    backgroundColor: "white",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#00aaff",
  },
}))(LinearProgress);

const makeCsvFileData = (data) => {
  const filteredData = data.filter(
    (item) => !["SKIPPED", "EMPTY"].includes(item.status)
  );
  const itemsCorrected = filteredData.filter(
      (item) => item.status === "CORRECTED"
    ),
    itemsSelected = filteredData.filter((item) => item.selected),
    itemsRanked = filteredData.filter((item) => item.rank === 0);
  return uniqBy(
    itemsCorrected
      .concat(itemsSelected)
      .concat(itemsRanked)
      .concat(filteredData),
    "item"
  ).sort((a, b) => a.item.localeCompare(b.item));
};

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
    fontSize: 20,
  },
}));

const CustomTooltip = () => {
  const classes = useStyles();

  return (
    <Tooltip
      title="Select or drag the electric bill files in the card on the left. The card on the right shows the processed files, their status and allows you to see the file and verify the data extracted."
      classes={{ tooltip: classes.customWidth }}
    >
      <div>
        <BiHelpCircle color="#00aaff" />
      </div>
    </Tooltip>
  );
};

const Component = () => {
  const history = useHistory();
  const defaultFilterValue = {
    label: "All files",
    value: "all",
  };

  const [filterSelectionValue, setFilterSelectionValue] = useState(
    defaultFilterValue
  );
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDrop, setLoadingDrop] = useState(false);
  const [loadingDownloadAll, setLoadingDownloadAll] = useState(false);
  const [message, setMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [candidates, setCandidates] = useState([]);

  const accessToken = localStorage.getItem("accessToken");
  const currentSessionFiles = localStorage.getItem("currentSessionFiles");
  const userEmail = localStorage.getItem("email");

  const columns = [
    {
      Header: (props) => (
        <div className="table__header min_width">
          Filename
          <div className="table__header-icon">
            {props.isSorted ? (
              props.isSortedDesc ? (
                <MdKeyboardArrowDown />
              ) : (
                <MdKeyboardArrowUp />
              )
            ) : (
              <BsChevronExpand />
            )}
          </div>
        </div>
      ),
      accessor: "filename",
      Cell: (props) => {
        return (
          <div
            style={{ display: "flex" }}
            data-for="tooltip"
            data-tip={props.row.original.filename}
          >
            <span>{(props.value || "").substring(0, 12)}</span>
            {(props.value || "").length > 12 && <span>...</span>}
          </div>
        );
      },
    },
    {
      Header: (props) => (
        <div className="table__header min_width">
          Timestamp
          <div className="table__header-icon">
            {props.isSorted ? (
              props.isSortedDesc ? (
                <MdKeyboardArrowDown />
              ) : (
                <MdKeyboardArrowUp />
              )
            ) : (
              <BsChevronExpand />
            )}
          </div>
        </div>
      ),
      accessor: "timestamp",
      Cell: (props) => (
        <div>
          <span>{props.value.replace("T", " ").split(".")[0]}</span>
        </div>
      ),
    },
    {
      Header: (props) => (
        <div className="table__header min_width">
          Reliability
          <div className="table__header-icon">
            {props.isSorted ? (
              props.isSortedDesc ? (
                <MdKeyboardArrowDown />
              ) : (
                <MdKeyboardArrowUp />
              )
            ) : (
              <BsChevronExpand />
            )}
          </div>
        </div>
      ),
      accessor: "status",
      Cell: (props) => {
        const selfCandidates = candidates.filter(
          ({ filename }) => filename === props.row.original.filename
        );
        return <ExtractedScoreBar {...props} candidates={selfCandidates} />;
      },
    },
    {
      Header: (props) => <div></div>,
      accessor: "icons",
      disableFilters: true,
      Cell: (props) => {
        const [loadingItem, setLoadingItem] = useState(false);
        return (
          <div className="download__option">
            <div className="download__icons">
              <div data-for="tooltip" data-tip={props.row.original.status}>
                {props.row.original.status === "VERIFIED" ? (
                  <MdCheckCircle color="green" />
                ) : props.row.original.status === "EXTRACTED" ? (
                  <MdError color="#fbb558" />
                ) : (
                  <IoRemoveCircle color="#00aaff" />
                )}
              </div>

              <div
                data-for="tooltip"
                data-tip={"Click to see words extracted"}
                onClick={(e) => getWords(props.row.original.filename)}
                style={{ cursor: "pointer" }}
              >
                {!loadingItem && (
                  <div>
                    <AiFillEye />
                  </div>
                )}
                {loadingItem && (
                  <div>
                    <CircularProgress size={15} color={"white"} />
                  </div>
                )}
              </div>
              <div
                data-for="tooltip"
                data-tip={"Download extractions"}
                onClick={(e) =>
                  handleDownloadFile(
                    props.row.original.filename,
                    setLoadingItem
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <MdFileDownload />
              </div>
              <div
                data-for="tooltip"
                data-tip={"Remove file"}
                onClick={(e) =>
                  handleDeleteFile(props.row.original.filename, setLoadingItem)
                }
                style={{ cursor: "pointer" }}
              >
                <RiDeleteBin5Fill />
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const getWords = (fileName) => {
    setLoading(true);
    localStorage.setItem("selectedFile", fileName);
    history.push("/viewer");
  };

  const getUrlApiLinkListByFilterValue = () => {
    if (filterSelectionValue.value === "session")
      return composeApiListFilesFromCurrentSession(
        userEmail,
        currentSessionFiles
      );

    if (filterSelectionValue.value === "user")
      return composeApiListFilesFromAuthor(userEmail);
    return urlApiListAllFiles;
  };

  const handleDownloadFile = (filename, setLoadingItem) => {
    setLoadingItem(true);
    axios
      .get(urlApiGetWords + `?filename=${filename}`, {
        headers: {
          "access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data) {
          downloadCSV(makeCsvFileData(res.data.items), csvHeader, filename);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadingItem(false));
  };

  const handleDownloadAll = (e) => {
    setLoadingDownloadAll(true);
    const files = fileData.map((file) => file.filename);
    axios
      .get(
        filterSelectionValue.value === "all"
          ? urlApiDownloadAll
          : composeDownloadFiles(files),
        {
          headers: {
            "access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          const filenames = uniqBy(res.data.items, "filename").map(
            (file) => file.filename
          );
          const csvData = filenames.reduce(
            (carry, filename) =>
              carry.concat(
                makeCsvFileData(
                  res.data.items.filter((file) => file.filename === filename)
                )
              ),
            []
          );
          downloadCSV(csvData, csvHeader, "invoices");
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadingDownloadAll(false));
  };

  const handleChangeFilterSelect = (option) => {
    setFilterSelectionValue(option);
  };

  const handleDrop = async (acceptedFiles) => {
    setLoadingDrop(true);

    const basePromise = (file) => {
      let formData = new FormData();
      formData.append("file", file);
      return new Promise((resolve) =>
        axios
          .post(urlApiUpload + `?author=${userEmail}`, formData, {
            headers: {
              "access-token": accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then(resolve)
          .catch((error) => resolve(error))
      );
    };

    await axios.get(urlApiListAllFiles, {
      headers: {
        "access-token": accessToken,
      },
    });

    let successfulUploads = [],
      failedUploads = [];

    for (let index = 0; index < acceptedFiles.length; index++) {
      setUploadProgress(
        Math.min(((index + 1) / acceptedFiles.length) * 100),
        100
      );
      await basePromise(acceptedFiles[index]).then((response) => {
        if (!response.isAxiosError) successfulUploads.push(response);
        else failedUploads.push(response);
      });
    }

    if (successfulUploads.length > 0) {
      const uploadedFiles = successfulUploads.reduce(
        (carry, upload) => carry.concat(upload.data.filename),
        []
      );

      localStorage.setItem(
        "currentSessionFiles",
        (currentSessionFiles || "")
          .split(",")
          .concat(uploadedFiles)
          .filter((value) => !!value)
          .join()
      );
      setMessage("File data extracted!");
    }
    if (failedUploads.length > 0) {
      console.error(failedUploads);

      console.error(failedUploads[0].response.data.detail);
      setMessage(
        failedUploads.map((fail) => `${message} ${fail.response.data.detail}`)
      );
    }
    setLoadingDrop(false);
    setUploadProgress(0);
  };

  const handleExtract = async () => {
    setLoading(true);
    await axios
      .get(getUrlApiLinkListByFilterValue(), {
        headers: {
          "access-token": accessToken,
        },
      })
      .then((res) => {
        let responseFileData;
        if (filterSelectionValue.value === "session") {
          responseFileData = res.data.files.filter((file) =>
            currentSessionFiles.split(",").includes(file.filename)
          );
        } else {
          responseFileData = res.data.files;
        }
        setFileData(responseFileData);
        return responseFileData;
      })
      .then(async (responseFileData) => {
        await axios
          .get(`${urlApiGetWords}?selected=true`, {
            headers: {
              "access-token": accessToken,
            },
          })
          .then((response) => {
            const responseFilenames = responseFileData.reduce(
              (carry, file) => carry.concat(file.filename),
              []
            );
            const filteredList = response.data.items.filter(({ filename }) =>
              responseFilenames.includes(filename)
            );
            setCandidates(filteredList);
          });
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const handleDeleteFile = (filename, setLoadingItem) => {
    setLoadingItem(true);
    axios
      .post(
        urlApiDeleteFile + `?filename=${filename}`,
        {},
        {
          headers: {
            "access-token": accessToken,
          },
        }
      )
      .then((res) => {
        handleExtract();
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoadingItem(false);
      });
  };

  useEffect(() => {
    if (!loadingDrop && filterSelectionValue.value) handleExtract();
  }, [filterSelectionValue.value, loadingDrop]);

  return (
    <div className="page-extractor__container">
      <Header />
      <div className="body__container">
        <div className="how-it-works" style={{ zIndex: 10 }}>
          How it works{" "}
          <div style={{ marginLeft: 10 }}>
            <CustomTooltip />
          </div>
        </div>
        <div className="data__container">
          <div className="extract__container">
            <span className="extract__title">Files to extract</span>
            <div className="extract__main">
              <Dropzone
                onDrop={handleDrop}
                accept="application/pdf, image/jpeg, image/png"
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                }) => {
                  return (
                    <div
                      {...getRootProps({
                        className: `dropzone`,
                      })}
                    >
                      <input {...getInputProps()} />

                      <p>
                        {!loadingDrop &&
                          isDragActive &&
                          isDragReject &&
                          "File not supported"}
                        {!loadingDrop &&
                          isDragActive &&
                          !isDragReject &&
                          "Drop your files here"}
                        {!loadingDrop &&
                          !isDragActive &&
                          "Drag and drop your files here"}
                        {loadingDrop && (
                          <CircularProgress
                            size={50}
                            style={{ color: "#FFFFFF" }}
                          />
                        )}
                      </p>
                    </div>
                  );
                }}
              </Dropzone>
            </div>
            {loadingDrop && (
              <div style={{ width: 400, marginTop: 10 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={uploadProgress}
                />
              </div>
            )}
            {message && (
              <div
                className={
                  message === "File data extracted!" ? "success" : "error"
                }
              >
                {message}
              </div>
            )}
          </div>

          <div className="download__container">
            <div className="extract__table-upper">
              <span className="extract__table-title">
                List of extracted files
              </span>
              <div style={{ marginBottom: 5 }}>
                <Select
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      cursor: "pointer",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 10,
                    }),
                  }}
                  options={[
                    { label: "All files", value: "all" },
                    { label: "Current user", value: "user" },
                    { label: "Current session", value: "session" },
                  ]}
                  value={filterSelectionValue}
                  onChange={handleChangeFilterSelect}
                  isSearchable={false}
                />
              </div>
            </div>
            <ReactTooltip id="tooltip" getContent={(dataTip) => dataTip} />
            <ExtractorTable
              data={fileData}
              columns={columns}
              sorted={true}
              isLoading={loading}
            />
            <div className="footer_container">
              <button
                className="btn color-button btn-block"
                onClick={handleDownloadAll}
              >
                Download all
              </button>
              <button className="refresh_icon" onClick={handleExtract}>
                {!loadingDownloadAll && (
                  <RefreshIcon
                    style={{ width: 30, height: 30, color: "#00AAFF" }}
                  />
                )}
                {loadingDownloadAll && (
                  <div>
                    <CircularProgress
                      size={20}
                      style={{ width: 21, height: 21, color: "#00AAFF" }}
                    />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
