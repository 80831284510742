import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import MessageCard from "./components/MessageCard";

const Modal = ({ setOpen }) => {
  const handleClick = (e) => {
    if (e.target.className !== "modal-backdrop") {
      return;
    }
    setOpen(false);
  };

  const close = () => {
    setOpen(false);
  };

  const getClick = () => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  };

  useEffect(getClick, []);

  return ReactDOM.createPortal(
    <MessageCard close={close} />,
    document.getElementById("root")
  );
};

export default Modal;
