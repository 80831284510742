import React from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import ReactTooltip from "react-tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  canFilter,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        type={canFilter ? "" : "hidden"}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
          color: "black",
        }}
      />
    </span>
  );
};

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      size="10"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`${count} files...`}
    />
  );
};

const Component = ({ data = [], columns = [], sorted, isLoading }) => {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <div className="list__container">
      <div className="list__main" style={{ height: 610, width: 700 }}>
        <div className="table-extractor__container">
          {isLoading && (
            <div style={{ marginLeft: 300 }}>
              <CircularProgress
                size={50}
                style={{ marginTop: 250, color: "#FFFFFF" }}
              />
            </div>
          )}
          {!isLoading && (
            <div className="table__main">
              <table {...getTableProps()} className="table">
                <thead className="table__head">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="th-extractor"
                          {...column.getHeaderProps(
                            sorted && column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header", {
                            isSortedDesc: column.isSortedDesc,
                            isSorted: column.isSorted,
                          })}
                          <div className="table__filter">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="table__body">
                  {rows.map((row) => {
                    prepareRow(row);

                    return (
                      <tr {...row.getRowProps()} className="table__row">
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
                <ReactTooltip id="tooltip" getContent={(dataTip) => dataTip} />
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Component;
