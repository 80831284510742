import React from "react";
import { makeStyles } from "@material-ui/core";
import { mean, isNull } from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";

const getBackgroundColor = (value) => {
  switch (true) {
    case value >= 80:
      return "#4caf50"; // green
    case value >= 50 && value < 80:
      return "#f9a825"; // yellow
    case value >= 25 && value < 50:
      return "#ef6c00"; // orange
    default:
      return "#f44336"; // red
  }
};

const useStyles = makeStyles({
  root: {
    borderRadius: 5,
    backgroundColor: "white",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: getBackgroundColor,
  },
});

const BorderLinearProgressTable = (value) => {
  const classes = useStyles(value);
  return (
    <LinearProgress
      variant="determinate"
      className={classes.root}
      classes={{ bar: classes.bar }}
      value={value}
    />
  );
};

const Component = (props) => {
  const postedDate = new Date(props.row.original.timestamp);
  const migrationDate = new Date("2021-05-24T13:51:00");
  const candidates = props.candidates || [];

  const hasScore =
    candidates.length > 0 &&
    postedDate > migrationDate &&
    ["EXTRACTED", "SELECTED", "CORRECTED", "VERIFIED"].includes(props.value);
  const meanScore = mean(
    candidates.reduce(
      (carry, { score }) => (isNull(score) ? carry : carry.concat(score)),
      []
    )
  );

  return (
    <div>
      {hasScore && (
        <span data-for="tooltip" data-tip={(meanScore * 100).toFixed(2)}>
          {
            <div style={{ width: 100, marginTop: 10 }}>
              {BorderLinearProgressTable(meanScore * 100)}
            </div>
          }
        </span>
      )}
      {!hasScore && (
        <span data-for="tooltip" data-tip="Not extract data">
          -
        </span>
      )}
    </div>
  );
};

export default Component;
