import React, { useState } from "react";
import axios from "axios";
import LogoElint from "../../assets/logo-elint.png";
import { SmallLogo } from "../../assets";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const Component = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const handleEmail = (event) => setEmail(event.target.value);
  const handlePassword = (event) => setPassword(event.target.value);
  const urlApiAuth = `https://lensapi.elint.services/login/authenticate`;
  const urlApiRefresh = `https://lensapi.elint.services/login/refresh`;
  const handleSubmit = (event) => {
    event.preventDefault();
    const userJSON = JSON.stringify({
      email: email,
      password: password,
    });
    setLoading(true);
    axios
      .post(urlApiAuth, userJSON)
      .then(async (res) => {
        const userToken = {
          refreshToken: res.data.RefreshToken,
        };

        localStorage.setItem("refreshToken", res.data.RefreshToken);
        localStorage.setItem("accessToken", res.data.AccessToken);
        localStorage.setItem("email", email);
        localStorage.setItem("userIdToken", res.data.IdToken);
        localStorage.setItem("currentSessionFiles", "");
        localStorage.setItem("selectedFile", "");

        await axios
          .post(urlApiRefresh, userToken)
          .then(async (res) => {
            history.push("/extractor");
          })
          .catch((error) => {
            setMessage("Invalid token");
          });
      })
      .catch((error) => {
        console.log(error);
        setMessage("Invalid user");
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="page__container page-main__container">
      <div className="logo__container">
        <div className="logo__main">
          <div>
            <img src={LogoElint} alt="logo" />
          </div>
          <div className="lens">LENS</div>
        </div>
      </div>
      <div className="main__container">
        <div className="engie">
          <SmallLogo />
        </div>
        <div className="welcome__container">
          <span>Welcome</span>
          <h3>
            Here you will be able to extract information from images and PDFs
            from electrical bills into easily human readable excel files.
          </h3>
        </div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="form-group">
            <input
              type="user"
              className="form-control"
              placeholder="User"
              onChange={(e) => {
                handleEmail(e);
              }}
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              onChange={(e) => {
                handlePassword(e);
              }}
            />
            <Link to="/forgotpassword" className="forgot-password">
              Forgot password?
            </Link>
            {message && <div className="error">{message}</div>}
          </div>
          <div className="buttons__container">
            <Link to="/register" className="btn color-button btn-block">
              REGISTER
            </Link>
            <button type="submit" className="btn color-button btn-block">
              {!loading && "LOGIN"}
              {loading && (
                <div style={{ marginTop: 4 }}>
                  <CircularProgress size={34} color={"white"} />
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Component;
