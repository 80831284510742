import axios from "axios";
import { isEqual, uniqBy } from "lodash";
const composeUrlVerifyFile = (fileName) =>
  `https://oclintapi.elint.services/storage/verify?filename=${fileName}`;

const composeUrlSelectRank = (fileName, item, rank) =>
  `https://oclintapi.elint.services/extractions/select?filename=${fileName}&item=${item}&rank=${rank}`;
const composeUrlCorrectCandidate = (fileName, item, candidate) =>
  `https://oclintapi.elint.services/extractions/correct?filename=${fileName}&item=${item}&candidate=${candidate}`;

const handleFormSubmit = (values, actions, initialValues) => {
  const isPristine = isEqual(values, initialValues);
  const fileName = localStorage.getItem("selectedFile");
  const accessToken = localStorage.getItem("accessToken");
  if (isPristine) {
    return axios.post(
      composeUrlVerifyFile(fileName),
      {},
      {
        headers: {
          "access-token": accessToken,
        },
      }
    );
  }

  const promisesArray = values.candidates.reduce((carry, candidate, index) => {
    if (isEqual(candidate, initialValues.candidates[index])) {
      return carry;
    }

    return carry.concat(
      new Promise((resolve, reject) =>
        axios
          .post(
            candidate.corrected
              ? composeUrlCorrectCandidate(
                  fileName,
                  candidate.item,
                  candidate.candidate
                )
              : composeUrlSelectRank(fileName, candidate.item, candidate.rank),
            {},
            {
              headers: {
                "access-token": accessToken,
              },
            }
          )
          .then(resolve)
          .catch(reject)
      )
    );
  }, []);

  return Promise.all(promisesArray);
};

const makeCandidateInitialValue = (data) => {
  const itemsCorrected = data.filter((item) => item.status === "CORRECTED"),
    itemsSelected = data.filter((item) => item.selected),
    itemsRanked = data.filter((item) => item.rank === 0);
  return uniqBy(
    itemsCorrected.concat(itemsSelected).concat(itemsRanked).concat(data),
    "item"
  ).sort((a, b) => a.item.localeCompare(b.item));
};

export {
  composeUrlVerifyFile,
  composeUrlSelectRank,
  composeUrlCorrectCandidate,
  handleFormSubmit,
  makeCandidateInitialValue,
};
