import React from "react";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";

import { PrivateRoute } from "./helpers/PrivateRoute";

import Login from "./components/Login";
import Register from "./components/Register";
import Extractor from "./components/Extractor";
import Viewer from "./components/Viewer";
import ConfirmAccount from "./components/ConfirmAccount";
import ForgotPassword from "./components/ForgotPassword";
import ConfirmForgotPassword from "./components/ConfirmForgotPassword";

function App() {
  const urlApiRefresh = `https://lensapi.elint.services/login/refresh`;
  const userRefreshToken = localStorage.getItem("refreshToken");

  const refreshToken = () =>
    axios
      .post(urlApiRefresh, { refreshToken: userRefreshToken })
      .then((res) => {
        localStorage.setItem("userIdToken", res.data.IdToken);
        localStorage.setItem("accessToken", res.data.AccessToken);
      })
      .catch((error) => console.error(error));

  const TIME_INTERVAL = 60 * 15 * 1000; // 15 minutos
  setInterval(refreshToken, TIME_INTERVAL);

  return (
    <Router>
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Switch>
              <PrivateRoute path="/viewer" component={Viewer} />
              <PrivateRoute path="/extractor" component={Extractor} />
              <Route path="/register" component={Register} />
              <Route path="/login" component={Login} />
              <Route exact path="/" component={Login} />
              <Route exact path="/confirmaccount" component={ConfirmAccount} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route
                exact
                path="/confirmforgotpassword"
                component={ConfirmForgotPassword}
              />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
