import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Component = (props) => {
  return (
    <div style={{ marginTop: 4, marginRight: 15 }}>
      <CircularProgress size={20} color={"primary"} />
    </div>
  );
};

export default Component;
