const urlApiUpload = `https://oclintapi.elint.services/run/extract`;
const urlApiGetWords = `https://oclintapi.elint.services/storage/get-snd-records`;
const urlApiDownloadAll = `https://oclintapi.elint.services/storage/get-snd-records`;
const urlApiListAllFiles = `https://oclintapi.elint.services/storage/list-files`;
const urlApiDeleteFile = `https://oclintapi.elint.services/storage/remove`;
const composeApiListFilesFromAuthor = (email) =>
  `https://oclintapi.elint.services/storage/list-files?author=${email}`;

// const composeApiListFilesFromCurrentSession = (email, files = "") =>
//   `https://oclintapi.elint.services/storage/list-files?author=${email}&filename=${files
//     .split(",")
//     .join("&filename=")}`;
const composeApiListFilesFromCurrentSession = (email, files = "") =>
  `https://oclintapi.elint.services/storage/list-files`;

const composeDownloadFiles = (files = []) =>
  "https://oclintapi.elint.services/storage/get-snd-records?filename=" +
  files.join("&filename=");

export {
  urlApiUpload,
  urlApiGetWords,
  urlApiDownloadAll,
  urlApiListAllFiles,
  urlApiDeleteFile,
  composeApiListFilesFromAuthor,
  composeApiListFilesFromCurrentSession,
  composeDownloadFiles,
};
