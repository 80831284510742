import React, { useState } from "react";
import axios from "axios";
import LogoElint from "../../assets/logo-elint.png";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const Component = () => {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const handleCode = (event) => setCode(event.target.value);
  const handleEmail = (event) => setEmail(event.target.value);
  const [loading, setLoading] = useState(false);
  const urlApiCode = `https://lensapi.elint.services/register/confirm-signup`;
  const handleSubmit = (event) => {
    event.preventDefault();
    const codeJSON = JSON.stringify({
      email: email,
      confirmationCode: code,
    });
    setLoading(true);
    axios
      .post(urlApiCode, codeJSON)

      .then((res) => {
        history.push("/");
      })
      .catch((error) => {
        setMessage("Invalid user");
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="page__container page-main__container">
      <div className="logo__container">
        <div className="logo__main">
          <div>
            <img src={LogoElint} alt="logo" />
          </div>
          <div className="lens">LENS</div>
        </div>
      </div>
      <div className="main__container">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          style={{ marginTop: 350 }}
        >
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              onChange={(e) => {
                handleEmail(e);
              }}
            />
            <input
              type="code"
              className="form-control"
              placeholder="Verification code"
              onChange={(e) => {
                handleCode(e);
              }}
            />
            {message && <div className="error">{message}</div>}
          </div>
          <div
            className="buttons__container"
            style={{ justifyContent: "center" }}
          >
            <button type="submit" className="btn color-button btn-block">
              {!loading && "VERIFY ACCOUNT"}
              {loading && (
                <div>
                  <CircularProgress
                    size={20}
                    style={{ width: 21, height: 21 }}
                  />
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Component;
