import React, { useState, useEffect } from "react";
import { SmallLogo } from "../../assets";
import LogoElintSolo from "../../assets/logo-elint-solo.png";
import { BiChevronDown, BiLogOut } from "react-icons/bi";
import Modal from "../Modal";
import { Link } from "react-router-dom";
import axios from "axios";

const Component = () => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const email = localStorage.getItem("email");
  const accessToken = localStorage.getItem("accessToken");
  const urlApiCode = `https://lensapi.elint.services/login/forgot-password`;
  const urlApiUser = `https://lensapi.elint.services/user/me`;
  const sendCode = () => {
    console.log(
      setTimeout(() => console.warn(localStorage.getItem("email")), 500)
    );
    const codeJSON = JSON.stringify({
      email: email,
    });
    axios
      .post(urlApiCode, codeJSON)

      .then((res) => {
        console.log("enviado");
        setOpen(!open);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUser = () => {
    axios
      .get(urlApiUser, {
        headers: {
          "Access-Token": accessToken,
        },
      })
      .then((res) => setName(res.data.Username))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="header__container">
      <Link to="/extractor">
        <div className="elint__lens-logo">
          <img src={LogoElintSolo} alt="logo" className="logo-elint-solo" />
          <div className="lens__header">LENS</div>
        </div>
      </Link>
      <div className="profile__container">
        {name}
        <div className="letter__container">
          <div className="letter__main">
            <SmallLogo />
          </div>
        </div>
        <button onClick={() => setShow(!show)} className="button-header">
          <BiChevronDown />
        </button>
        {show && (
          <div className="dropdown-header__container">
            <div
              className="dropdown-header__option"
              onClick={() => {
                sendCode();
              }}
            >
              Change password
            </div>
            <Link to="/">
              <div
                className="dropdown-header__option"
                onClick={() => {
                  localStorage.setItem("email", "");
                  localStorage.setItem("accessToken", "");
                }}
              >
                Logout
                <div style={{ marginLeft: 10, marginTop: -3 }}>
                  <BiLogOut />
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
      {open && <Modal setOpen={setOpen} />}
    </div>
  );
};

export default Component;
