import React, { useState } from "react";
import { get } from "lodash";
import axios from "axios";
import LogoElint from "../../assets/logo-elint.png";
import { Back } from "../../assets";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

const Component = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const urlAPI = `https://lensapi.elint.services/register/signup`;

  const handleName = (event) => setName(event.target.value);
  const handleEmail = (event) => setEmail(event.target.value);
  const handlePassword = (event) => setPassword(event.target.value);
  const handleConfirmPassword = (event) =>
    setConfirmPassword(event.target.value);
  const handleSubmit = (event) => {
    event.preventDefault();
    const regex = `[a-zA-Z0-9]+\.[a-zA-Z0-9]+@engie\.com`;

    if (confirmPassword !== password) {
      return setMessage("Passwords doesn't match");
    }

    if (!email.match(regex)) {
      return setMessage("Email not authorized, try engie.com domain!");
    }

    const userJSON = JSON.stringify({
      email: email,
      password: password,
      name: name,
    });
    setLoading(true);
    axios
      .post(urlAPI, userJSON)
      .then((res) => {
        history.push("/confirmaccount");
      })
      .catch((error) => {
        const errorMessage = get(error, "response.data.Message");
        if (errorMessage) setMessage(errorMessage);
        else setMessage("Invalid user or password, please check it again.");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="page__container page-main__container">
      <div className="logo__container">
        <div className="logo__main">
          <div>
            <img src={LogoElint} alt="logo" />
          </div>
          <div className="lens">LENS</div>
        </div>
      </div>
      <div className="main__container">
        <div className="welcome__container">
          <span>Register</span>
        </div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="form-group">
            <input
              type="name"
              onChange={(e) => {
                handleName(e);
              }}
              className="form-control"
              placeholder="Fullname"
            />
          </div>

          <div
            className="form-group"
            data-for="tooltip"
            data-tip={
              "at least 8 digits, 1 uppercase, 1 number and 1 special digit (!,@,#,$...)"
            }
          >
            <input
              type="password"
              onChange={(e) => {
                handlePassword(e);
              }}
              className="form-control"
              placeholder="Password"
            />
            <ReactTooltip id="tooltip" getContent={(dataTip) => dataTip} />
          </div>
          <div className="form-group">
            <input
              type="password"
              onChange={(e) => {
                handleConfirmPassword(e);
              }}
              className="form-control"
              placeholder="Confirm Password"
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              onChange={(e) => {
                handleEmail(e);
              }}
              className="form-control"
              placeholder="Email"
            />
            {message && <div className="error">{message}</div>}
          </div>
          <div className="buttons__container">
            <Link to="/login" className="btn back-icon btn-block">
              <Back />
            </Link>
            <button type="submit" className="btn color-button btn-block">
              {!loading && "REGISTER"}
              {loading && (
                <div>
                  <CircularProgress
                    size={20}
                    style={{ width: 21, height: 21 }}
                  />
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Component;
