import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BiArrowBack, BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsChevronExpand } from "react-icons/bs";
import { AiFillFlag } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import { Formik, Form, FieldArray } from "formik";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AsyncSelect from "react-select/async";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactTooltip from "react-tooltip";

import Header from "../Header";
import Table from "./components/Table";
import LoadingIndicator from "./components/LoadingIndicator";
import SelectableInputField from "./components/SelectableInputField";

import { urlApiGetWords, urlGetPdf, urlApiListAll } from "./constants";
import { handleFormSubmit, makeCandidateInitialValue } from "./utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const statusMessages = {
  SKIPPED: "This item was skipped during extraction.",
  DEDUCED: "This item was deduced.",
  EMPTY: "Could not find this item in this file.",
  CORRECTED: "This item was inserted by a user",
};

const Component = () => {
  const history = useHistory();
  const hoverTimeoutKey = useRef();
  const [selectedFile, setSelectedFile] = useState(
    localStorage.getItem("selectedFile")
  );
  const [pdfLink, setPdfLink] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [lock, setLock] = useState(false);
  const [rectCoordinates, setRectCoordinates] = useState({});
  const [feedbackItemId, setFeedbackItemId] = useState();
  const [feedbackLoading, setFeedbackLoading] = useState(false);

  const { x1, x2, y1, y2 } = rectCoordinates;
  const accessToken = localStorage.getItem("accessToken");
  const selectedOption = { label: selectedFile, value: selectedFile };

  const formInitialValues = {
    fileName: selectedFile,
    candidates: makeCandidateInitialValue(data),
  };

  const handleOpenFeedbackDialog = useCallback(
    (feedbackItemId) => {
      setFeedbackItemId(feedbackItemId);
    },
    [setFeedbackItemId]
  );

  const handleClose = () => {
    setFeedbackItemId(null);
  };

  const sendFeedback = (feedback, item) => {
    const dataFeedback = new FormData();
    dataFeedback.append("feedback", feedback);
    setFeedbackLoading(true);
    axios
      .post(
        "https://oclintapi.elint.services" +
          `/${localStorage.getItem("email")}/file/${localStorage.getItem(
            "selectedFile"
          )}/item/${item}/feedback`,
        dataFeedback,
        {
          headers: {
            "access-token": accessToken,
          },
        }
      )
      .then((res) => handleClose())
      .catch((error) => console.log(error.response.data))
      .finally(() => setFeedbackLoading(false));
  };

  const handleLoadOptions = () =>
    axios
      .get(urlApiListAll, {
        headers: {
          "access-token": accessToken,
        },
      })
      .then((result) =>
        result.data.files.map((file) => ({
          value: file.filename,
          label: file.filename,
        }))
      );

  const handleFileChange = ({ value }) => {
    localStorage.setItem("selectedFile", value);
    setSelectedFile(value);
  };

  const handleMouseEnter = useCallback(
    (value) => {
      if (hoverTimeoutKey.current) clearTimeout(hoverTimeoutKey.current);
      hoverTimeoutKey.current = setTimeout(() => {
        const coordinates = data.find((dataRow) => dataRow.id === value);
        setRectCoordinates({
          x1: coordinates.x1,
          x2: coordinates.x2,
          y1: coordinates.y1,
          y2: coordinates.y2,
        });
      }, 100);
    },
    [hoverTimeoutKey, data, setRectCoordinates]
  );

  const handleMouseLeave = useCallback(
    (e) => {
      if (hoverTimeoutKey.current) clearTimeout(hoverTimeoutKey.current);
      if (!lock) setRectCoordinates({});
    },
    [hoverTimeoutKey, lock, setRectCoordinates]
  );

  const getData = useCallback(() => {
    setLoading(true);
    Promise.all([
      axios.get(urlApiGetWords + `?filename=${selectedFile}&normalize=true`, {
        headers: {
          "access-token": accessToken,
        },
      }),
      axios.get(urlGetPdf + `?filename=${selectedFile}&convert=PDF`, {
        headers: {
          "access-token": accessToken,
        },
      }),
    ])
      .then(([tableResponse, pdfResponse]) => {
        setPdfLink(pdfResponse.data.url);
        setData(tableResponse.data.items);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [setLoading, setPdfLink, setData, accessToken, selectedFile]);

  const goToPrevPage = () =>
    setPageNumber(pageNumber < 2 ? pageNumber : pageNumber - 1);
  const goToNextPage = () =>
    setPageNumber(numPages === pageNumber ? pageNumber : pageNumber + 1);

  useEffect(() => {
    getData();
  }, [selectedFile, getData]);

  const columns = useMemo(
    () => [
      {
        Header: (props) => (
          <div className="table__header">
            ITEM
            <div className="table__header-icon">
              {props.isSorted && props.isSortedDesc && <MdKeyboardArrowDown />}
              {props.isSorted && !props.isSortedDesc && <MdKeyboardArrowUp />}
              {!props.isSorted && <BsChevronExpand />}
            </div>
          </div>
        ),
        accessor: "item",
        Cell: (props) => {
          return (
            <div>
              <span>{props.value}</span>
            </div>
          );
        },
      },
      {
        Header: (props) => (
          <div className="table__header" style={{ minWidth: 72 }}>
            SCORE
            <div className="table__header-icon">
              {props.isSorted && props.isSortedDesc && <MdKeyboardArrowDown />}
              {props.isSorted && !props.isSortedDesc && <MdKeyboardArrowUp />}
              {!props.isSorted && <BsChevronExpand />}
            </div>
          </div>
        ),
        accessor: "score",
        Cell: (props) => (
          <div>
            {props.row.original.status === "EMPTY" && <span>Not found</span>}
            {["SKIPPED", "DEDUCED", "CORRECTED"].includes(
              props.row.original.status
            ) && <span>N/A</span>}
            {!["SKIPPED", "EMPTY", "DEDUCED", "CORRECTED"].includes(
              props.row.original.status
            ) && (
              <span>{`${Number(props.value * 100).toFixed(2)}%` || "N/A"}</span>
            )}
          </div>
        ),
      },
      {
        Header: (props) => (
          <div className="table__header">
            RESULT
            <div className="table__header-icon">
              {props.isSorted && props.isSortedDesc && <MdKeyboardArrowDown />}
              {props.isSorted && !props.isSortedDesc && <MdKeyboardArrowUp />}
              {!props.isSorted && <BsChevronExpand />}
            </div>
          </div>
        ),
        accessor: "results",
        Cell: (props) => (
          <SelectableInputField
            item={props.row.original.item}
            data={data}
            name={`candidates[${props.row.index}]`}
            status={props.row.original.status}
          />
        ),
      },
      {
        Header: (props) => <div></div>,
        accessor: "icons",
        Cell: (props) => (
          <div className="table__icons">
            {props.row.original.status === "EXTRACTED" && (
              <div
                onMouseEnter={(e) => handleMouseEnter(props.row.original.id)}
                onMouseLeave={handleMouseLeave}
                onClick={(e) => setLock((lock) => !lock)}
                className="table__icon"
              >
                <AiFillEye />
              </div>
            )}
            {props.row.original.status !== "EXTRACTED" && (
              <div
                className="table__icon"
                data-tip={statusMessages[props.row.original.status]}
              >
                <ReactTooltip />
                <AiFillEyeInvisible />
              </div>
            )}
            <div className="table__icon">
              <div
                onClick={() => {
                  handleOpenFeedbackDialog(props.cell.row.values.item);
                }}
              >
                <AiFillFlag />
              </div>
            </div>
          </div>
        ),
      },
    ],
    [handleMouseLeave, handleMouseEnter, handleOpenFeedbackDialog, data, lock]
  );

  return (
    <div className="page-extractor__container">
      <Header />
      <div className="body__container">
        <div className="back__button-container">
          <div
            className="back__button"
            onClick={() => history.push("/extractor")}
          >
            <BiArrowBack />
          </div>
        </div>
        <div className="viewer__wrapper">
          <div className="viewer__container">
            <div className="pdf_title">
              <AsyncSelect
                onChange={handleFileChange}
                defaultOptions={true}
                value={selectedOption}
                loadOptions={handleLoadOptions}
                components={{ LoadingIndicator }}
              />
            </div>
            <div className="viewer__main">
              <TransformWrapper>
                <TransformComponent>
                  <div className="position__container">
                    {x1 && (
                      <div
                        className="position__main"
                        style={{
                          top: `${y1 * 100}%`,
                          left: `${x1 * 100}%`,
                          width: `${(x2 - x1) * 100}%`,
                          height: `${(y2 - y1) * 100}%`,
                        }}
                      />
                    )}
                    {loading && (
                      <div>
                        <CircularProgress
                          size={50}
                          style={{ color: "#FFFFFF" }}
                        />
                      </div>
                    )}
                    {!loading && (
                      <Document
                        file={pdfLink}
                        onLoadSuccess={({ _pdfInfo }) =>
                          setNumPages(_pdfInfo.numPages)
                        }
                      >
                        <Page width={750} pageNumber={pageNumber} />
                      </Document>
                    )}
                  </div>
                </TransformComponent>
              </TransformWrapper>
            </div>
            <div className="viewer-buttons">
              <nav className="viewer-buttons">
                <button
                  onClick={goToPrevPage}
                  className="btn pdf_title btn-block"
                  style={
                    numPages === 1
                      ? {
                          pointerEvents: "none",
                          color: "grey",
                          cursor: "default",
                        }
                      : {}
                  }
                >
                  <BiChevronLeft />
                </button>
                <div className="pdf_title" style={{ cursor: "default" }}>
                  {pageNumber}
                </div>
                <button
                  onClick={goToNextPage}
                  className="btn pdf_title btn-block"
                  style={
                    numPages === 1
                      ? {
                          pointerEvents: "none",
                          color: "grey",
                          cursor: "default",
                        }
                      : {}
                  }
                >
                  <BiChevronRight />
                </button>
              </nav>
            </div>
          </div>
          <Dialog
            open={!!feedbackItemId}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <div className="feedback__title">Send feedback</div>
            <DialogContent>
              <div className="feedback__text">
                Please insert your feedback below
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendFeedback(e.target[0].value, feedbackItemId);
                }}
                style={{ marginTop: "-10px" }}
              >
                <div className="feedback__text">
                  <TextField
                    autoFocus
                    margin="dense"
                    id="flag"
                    label="Feedback"
                    fullWidth
                    color="#191b33"
                    size="Normal"
                  />
                </div>
                <DialogActions>
                  {feedbackLoading && (
                    <div>
                      <CircularProgress
                        size={25}
                        style={{ color: "#191B33" }}
                      />
                    </div>
                  )}
                  {!feedbackLoading && (
                    <div>
                      <button
                        className="feedback__button"
                        onClick={handleClose}
                        type="button"
                      >
                        Cancel
                      </button>
                      <button className="feedback__button" type="submit">
                        Send feedback
                      </button>
                    </div>
                  )}
                </DialogActions>
              </form>
            </DialogContent>
          </Dialog>
          <Formik
            onSubmit={(values, actions) =>
              handleFormSubmit(values, actions, formInitialValues)
            }
            initialValues={formInitialValues}
            enableReinitialize={true}
          >
            <Form style={{ marginTop: 0 }}>
              <FieldArray name="candidates">
                {() => (
                  <div className="list__container">
                    <Table
                      data={data}
                      columns={columns}
                      sorted={true}
                      isLoading={loading}
                    />
                  </div>
                )}
              </FieldArray>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Component;
