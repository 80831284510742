import React from "react";
import { useField } from "formik";
import { isEqual } from "lodash";
import Creatable from "react-select/creatable";

const Component = ({ name, item, data }) => {
  const [field, meta, helpers] = useField({ name });
  const { value } = meta;
  const { setValue } = helpers;

  const filteredOptionsByItem = data.filter(
    (option) =>
      option.item === item && !["SKIPPED", "EMPTY"].includes(option.status)
  );

  const handleChange = (option) => setValue(option);

  return (
    <Creatable
      name={field.name}
      options={filteredOptionsByItem}
      onChange={handleChange}
      value={value}
      getOptionLabel={(option) => option.candidate}
      getOptionValue={(option) => option.rank}
      getNewOptionData={(inputValue) => ({
        item,
        candidate: inputValue,
        rank: inputValue,
        corrected: true,
        score: "N/A",
        status: "CORRECTED",
      })}
    />
  );
};

const arePropsEqual = (prevProps, nextProps) =>
  isEqual(prevProps.data, nextProps.data) &&
  isEqual(prevProps.item, nextProps.item);

export default React.memo(Component, arePropsEqual);
