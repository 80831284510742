import React, { useMemo } from "react";
import { isEqual } from "lodash";
import { useTable, useSortBy } from "react-table";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { CSVLink } from "react-csv";
import { useFormikContext } from "formik";
import { downloadCSV } from "download-csv";

const Component = ({ columns, sorted, isLoading }) => {
  const {
    values,
    submitForm,
    dirty,
    isSubmitting,
    resetForm,
  } = useFormikContext();
  const data = useMemo(() => values.candidates || [], [values]);
  const filteredDataByStatus = data.filter(
    (item) => !["SKIPPED", "EMPTY"].includes(item.status)
  );
  const tableInstance = useTable({ columns, data }, useSortBy);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const loading = isLoading || isSubmitting;

  const csvHeader = [
    { label: "filename", key: "filename" },
    { label: "item", key: "item" },
    { label: "score", key: "score" },
    { label: "candidate", key: "candidate" },
    { label: "status", key: "status" },
    { label: "selected", key: "selected" },
  ];

  return (
    <>
      <div className="list__main">
        <div className="table__container">
          {isLoading && (
            <div>
              <CircularProgress
                size={50}
                style={{ marginTop: 270, color: "#FFFFFF" }}
              />
            </div>
          )}
          {!isLoading && (
            <div className="table__main">
              <table {...getTableProps()} className="table">
                <thead className="table__head">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            sorted && column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header", {
                            isSortedDesc: column.isSortedDesc,
                            isSorted: column.isSorted,
                          })}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="table__body">
                  {rows.map((row) => {
                    prepareRow(row);

                    return (
                      <tr {...row.getRowProps()} className="table__row">
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <div className="viewer-buttons">
        <button
          className="btn color-button btn-block"
          type="button"
          style={{
            width: 150,
            backgroundColor: dirty ? "#00aaff" : "#00d1b2",
          }}
          onClick={(e) => submitForm().catch((error) => console.error(error))}
        >
          {!loading && dirty && "Save changes"}
          {!loading && !dirty && "Verify document"}
          {loading && (
            <CircularProgress size={20} style={{ width: 21, height: 21 }} />
          )}
        </button>
        <button
          className="btn color-button btn-block"
          type="button"
          style={{
            width: 150,
            marginLeft: 10,
          }}
          onClick={() =>
            downloadCSV(filteredDataByStatus, csvHeader, values.fileName)
          }
        >
          Download CSV
        </button>
        <button
          className="btn color-button btn-block"
          type="button"
          style={{
            width: 150,
            marginLeft: 10,
          }}
          onClick={resetForm}
        >
          Undo changes
        </button>
      </div>
    </>
  );
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.columns, nextProps.columns) &&
  isEqual(prevProps.isSorted, nextProps.isSorted) &&
  prevProps.isLoading === nextProps.isLoading;
export default React.memo(Component, areEqual);
