import React from "react";

const SmallLogo = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80 11.3478C68.0772 3.77668 54.1694 -0.169283 40 -0.00106277C25.8306 -0.169283 11.9228 3.77668 0 11.3478C10.657 7.85708 23.1197 5.67337 40 5.67337C56.8803 5.67337 69.3269 7.85708 80 11.3478Z"
        fill="#00AAFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.185 51.7713C21.185 39.992 31.6163 35.1943 39.8388 34.5089C48.4482 33.7757 55.1874 35.6884 58.6699 39.4501C60.8303 41.8091 60.2822 45.0289 55.9775 45.2839C52.0597 45.523 44.6917 45.7621 38.5812 46.0171C35.1149 46.1447 33.2608 48.1052 33.2608 50.7193C33.2608 52.7914 34.7441 55.1664 38.7425 55.1823C44.466 55.1823 59.0246 55.3098 66.2475 55.3258C71.0036 55.3258 73.3575 52.5523 73.3575 46.8141C73.3575 37.7127 65.4736 23.9091 42.4345 23.9091C21.5558 23.9091 6.44904 34.7958 6.44904 52.6001C6.44904 77.8322 41.9186 85.531 67.9242 76.1427C70.391 75.266 72.632 73.4011 71.9065 70.532C71.2777 67.9817 69.2947 67.0094 66.312 67.6788C45.256 72.4288 21.185 67.36 21.185 51.7713Z"
        fill="#00AAFF"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SmallLogo;
