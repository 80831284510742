import React from "react";

const Back = () => (
  <svg
    width="50"
    height="31"
    viewBox="0 0 50 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 12.9167H10.6389L20.5833 3.6425L16.6667 0L0 15.5L16.6667 31L20.5833 27.3575L10.6389 18.0833H50V12.9167Z"
      fill="white"
    />
  </svg>
);

export default Back;
