import React from "react";

const Logo = () => (
  <svg
    width="274"
    height="278"
    viewBox="0 0 274 278"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M274 39.4379C233.164 13.1282 185.53 -0.583989 137 0.00057628C88.4699 -0.583989 40.8356 13.1282 0 39.4379C36.5002 27.3076 79.185 19.7192 137 19.7192C194.815 19.7192 237.445 27.3076 274 39.4379Z"
        fill="#00AAFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5587 179.905C72.5587 138.972 108.286 122.3 136.448 119.918C165.935 117.37 189.017 124.017 200.944 137.089C208.344 145.287 206.466 156.475 191.723 157.362C178.304 158.192 153.069 159.023 132.141 159.91C120.268 160.353 113.918 167.166 113.918 176.249C113.918 183.45 118.998 191.703 132.693 191.759C152.296 191.759 202.159 192.202 226.898 192.257C243.187 192.257 251.25 182.619 251.25 162.679C251.25 131.052 224.247 83.0843 145.338 83.0843C73.8287 83.0843 22.0879 120.915 22.0879 182.785C22.0879 270.467 143.571 297.22 232.641 264.596C241.089 261.549 248.765 255.069 246.28 245.099C244.126 236.236 237.334 232.858 227.119 235.184C155.002 251.69 72.5587 234.076 72.5587 179.905Z"
        fill="#00AAFF"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="274" height="278" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
