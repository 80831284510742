import React, { useState } from "react";
import axios from "axios";
import LogoElint from "../../assets/logo-elint.png";
import { useHistory } from "react-router-dom";

const Component = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const handleEmail = (event) => setEmail(event.target.value);
  const urlApiCode = `https://lensapi.elint.services/login/forgot-password`;
  const handleSubmit = (event) => {
    event.preventDefault();
    const codeJSON = JSON.stringify({
      email: email,
    });
    axios
      .post(urlApiCode, codeJSON)

      .then((res) => {
        history.push("/confirmforgotpassword");
      })
      .catch((error) => {
        console.log(error);
        setMessage("Invalid user");
      });
  };
  return (
    <div className="page__container page-main__container">
      <div className="logo__container">
        <div className="logo__main">
          <div>
            <img src={LogoElint} alt="logo" />
          </div>
          <div className="lens">LENS</div>
        </div>
      </div>
      <div className="main__container">
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          style={{ marginTop: 350 }}
        >
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              onChange={(e) => {
                handleEmail(e);
              }}
            />
            {message && <div className="error">{message}</div>}
          </div>
          <div
            className="buttons__container"
            style={{ justifyContent: "center" }}
          >
            <button type="submit" className="btn color-button btn-block">
              FORGOT PASSWORD
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Component;
