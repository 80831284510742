import React, { useState } from "react";
import { MdClear } from "react-icons/md";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Message = ({ close }) => {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const handleCode = (event) => setCode(event.target.value);
  const handleNewPassword = (event) => setNewPassword(event.target.value);
  const urlApiCode = `https://lensapi.elint.services/login/forgot-password/confirm`;
  const handleSubmit = (event) => {
    event.preventDefault();
    const codeJSON = JSON.stringify({
      email: localStorage.getItem("email"),
      newPassword: newPassword,
      code: code,
    });
    axios
      .post(urlApiCode, codeJSON)

      .then((res) => {
        console.log("senha alterada");
        history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="modal" style={{ display: "flex", position: "fixed" }}>
      <div className="modal-backdrop">
        <div className="message__container modal-content">
          <div className="message__header">
            <p></p>
            <button onClick={() => close()} className="message-header__button">
              <MdClear />
            </button>
          </div>
          <div className="message_info">
            <div className="message_title">
              <h3>Change password</h3>
            </div>
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="New password"
                  onChange={(e) => {
                    handleNewPassword(e);
                  }}
                />
                <input
                  type="code"
                  className="form-control"
                  placeholder="Verification code (check your email)"
                  onChange={(e) => {
                    handleCode(e);
                  }}
                />
              </div>
              <div
                className="buttons__container"
                style={{ justifyContent: "center" }}
              >
                <button type="submit" className="btn color-button btn-block">
                  CHANGE PASSWORD
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
